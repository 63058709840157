import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";
import FAQ from "./pages/Core/FAQ/Index.js";

// CORE
const Home = React.lazy(() => import("./pages/Core/Home/Index"));
const Search = React.lazy(() => import("./pages/Core/Search/Index.js"));
const DetailOffer = React.lazy(() =>
  import("./pages/Core/DetailOffer/Index.js")
);
const SearchKatalog = React.lazy(() =>
  import("./pages/Core/SearchKatalog/Index.js")
);
const KatalogDetail = React.lazy(() =>
  import("./pages/Core/KatalogDetail/Index.js")
);

const Privacy = React.lazy(() => import("./pages/Core/Privacy/Index.js"));
const Regulations = React.lazy(() =>
  import("./pages/Core/Regulations/Index.js")
);
const ContactForm = React.lazy(() =>
  import("./pages/Core/ContactForm/Index.js")
);

// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));
const ThankYouRegister = React.lazy(() =>
  import("./pages/Accounts/ThankYouRegister/Index.js")
);
const EmailVerified = React.lazy(() =>
  import("./pages/Accounts/EmailVerified/Index.js")
);
const ResetPassword = React.lazy(() =>
  import("./pages/Accounts/ForgotPassword/Index.js")
);

// UserPanel
const AddOffer = React.lazy(() =>
  import("./pages/UserPanel/AddOffer/Index.js")
);
const UpdateOffer = React.lazy(() =>
  import("./pages/UserPanel/UpdateOffer/Index.js")
);
const AddOfferThankYou = React.lazy(() =>
  import("./pages/UserPanel/AddOfferThankYou/Index.js")
);
const CatalogPromotion = React.lazy(() =>
  import("./pages/UserPanel/KatalogPromotion/Index.js")
);
const CatalogCheckout = React.lazy(() =>
  import("./pages/UserPanel/KatalogCheckout/Index.js")
);
const OfferPromotion = React.lazy(() =>
  import("./pages/UserPanel/OfferPromotion/Index.js")
);
const OfferPromotionCheckout = React.lazy(() =>
  import("./pages/UserPanel/PromotionCheckout/Index.js")
);
const Panel = React.lazy(() => import("./pages/UserPanel/Panel/Index.js"));
const Favourites = React.lazy(() =>
  import("./pages/UserPanel/Favourites/Index.js")
);
const Chat = React.lazy(() => import("./pages/UserPanel/Chat/Index.js"));
const Offers = React.lazy(() => import("./pages/UserPanel/Offers/Index.js"));
const Payments = React.lazy(() =>
  import("./pages/UserPanel/Payments/Index.js")
);
const Katalog = React.lazy(() => import("./pages/UserPanel/Katalog/Index.js"));
const AddCSV = React.lazy(() => import("./pages/UserPanel/AddCSV/Index.js"));
const SuccessAds = React.lazy(() =>
  import("./pages/UserPanel/SuccessAds/Index.js")
);
const AddUnpayedBaner = React.lazy(() =>
  import("./pages/UserPanel/AddUnpayedBaner/Index.js")
);
const AddBaner = React.lazy(() =>
  import("./pages/UserPanel/AddBaner/Index.js")
);
const Baners = React.lazy(() => import("./pages/UserPanel/Baners/Index.js"));

// Admin Panel
const AdminHome = React.lazy(() => import("./pages/AdminPanel/Home/Index.js"));
const AdminCatalogCategories = React.lazy(() =>
  import("./pages/AdminPanel/CatalogCategories/Index.js")
);
const AdminCatalogCategoryDetail = React.lazy(() =>
  import("./pages/AdminPanel/CatalogCategoryDetail/Index.js")
);
const AdminCategories = React.lazy(() =>
  import("./pages/AdminPanel/Categories/Index.js")
);
const AdminOrderPromotions = React.lazy(() =>
  import("./pages/AdminPanel/OrderPromotions/Index.js")
);
const AdminOrdersAll = React.lazy(() =>
  import("./pages/AdminPanel/OrdersAll/Index.js")
);
const AdminOrdersStopped = React.lazy(() =>
  import("./pages/AdminPanel/OrdersStopped/Index.js")
);
const AdminOrdersActive = React.lazy(() =>
  import("./pages/AdminPanel/OrdersActive/Index.js")
);
const AdminOrdersToAccept = React.lazy(() =>
  import("./pages/AdminPanel/OrdersToAccept/Index.js")
);
const AdminUserDetail = React.lazy(() =>
  import("./pages/AdminPanel/UserDetail/Index.js")
);
const AdminUsers = React.lazy(() =>
  import("./pages/AdminPanel/Users/Index.js")
);
const AdminCategoryDetail = React.lazy(() =>
  import("./pages/AdminPanel/CategoryDetail/Index.js")
);
const AdminMalwares = React.lazy(() =>
  import("./pages/AdminPanel/MalwareList/Index.js")
);
const AdminMalwareDetail = React.lazy(() =>
  import("./pages/AdminPanel/MalwareDetail/Index.js")
);
const AdminBaners = React.lazy(() =>
  import("./pages/AdminPanel/Baners/Index.js")
);
const AdminBanerDetail = React.lazy(() =>
  import("./pages/AdminPanel/BanerDetail/Index.js")
);
const AdminTransactions = React.lazy(() =>
  import("./pages/AdminPanel/Transactions/Index.js")
);
const AdminTransactionsDetail = React.lazy(() =>
  import("./pages/AdminPanel/TransactionDetail/Index.js")
);
const AdminDiscounts = React.lazy(() =>
  import("./pages/AdminPanel/Discounts/Index.js")
);
const AdminDiscount = React.lazy(() =>
  import("./pages/AdminPanel/Discount/Index.js")
);

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}
          <Route index element={<Home />} />
          <Route path="/regulamin" element={<Regulations />} />
          <Route path="/polityka-prywatnosci" element={<Privacy />} />
          <Route path="/szukaj" element={<Search />} />
          <Route path="/kontakt" element={<ContactForm />} />
          <Route path="/faq" element={<FAQ />} />
          <Route
            path="oferta/:offer_id/:offer_slug"
            element={<DetailOffer />}
          />
          <Route path="/katalog" element={<SearchKatalog />} />
          <Route
            path="/katalog/:cat_id/:cat_slug"
            element={<KatalogDetail />}
          />
          <Route
            path="/dziekujemy-oferta/:offer_id/:offer_slug"
            element={<AddOfferThankYou />}
          />
          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/zarejestruj-sie" element={<Register />} />
          <Route path="/dziekujemy-register" element={<ThankYouRegister />} />
          <Route path="/verify/:secret_code" element={<EmailVerified />} />
          <Route path="/przypomnij-haslo" element={<ResetPassword />} />

          {authCtx.isLoggedIn && (
            <>
              <Route path="/moje-konto/dodaj-oferte" element={<AddOffer />} />
              <Route
                path="/moje-konto/edytuj-oferte/:offer_id"
                element={<UpdateOffer />}
              />
              <Route
                path="/moje-konto/oferta-promocja/:offer_id"
                element={<OfferPromotion />}
              />
              <Route
                path="/moje-konto/oferta-promocja/:offer_id/:pakiet_id/checkout"
                element={<OfferPromotionCheckout />}
              />
              <Route
                path="moje-konto/katalog/promocja"
                element={<CatalogPromotion />}
              />
              <Route
                path="/moje-konto/katalog/promocja/:pakiet_id/checkout"
                element={<CatalogCheckout />}
              />
              <Route path="/moje-konto" element={<Panel />} />
              <Route path="/moje-konto/csv" element={<AddCSV />} />
              <Route path="/moje-konto/ulubione" element={<Favourites />} />
              <Route path="/moje-konto/wiadomosci" element={<Chat />} />
              <Route path="/moje-konto/oferty" element={<Offers />} />
              <Route path="/moje-konto/platnosci" element={<Payments />} />
              <Route path="/moje-konto/katalog" element={<Katalog />} />
              <Route path="/moje-konto/banery/dodaj" element={<AddBaner />} />
              <Route
                path="/moje-konto/banery/success/:my_id"
                element={<SuccessAds />}
              />
              <Route path="/moje-konto/banery/" element={<Baners />} />
              <Route
                path="/moje-konto/banery/nieoplacone/:baner_id"
                element={<AddUnpayedBaner />}
              />

              {/* ADMIN */}

              <Route path="/admin" element={<AdminHome />} />
              <Route
                path="/admin/katalog-kategorie"
                element={<AdminCatalogCategories />}
              />
              <Route
                path="/admin/katalog-kategorie/utworz"
                element={<AdminCatalogCategoryDetail />}
              />
              <Route
                path="/admin/katalog-kategorie/:cat_id"
                element={<AdminCatalogCategoryDetail />}
              />
              <Route path="/admin/kategorie" element={<AdminCategories />} />
              <Route
                path="/admin/oferta-promocje"
                element={<AdminOrderPromotions />}
              />
              <Route path="/admin/oferty" element={<AdminOrdersAll />} />
              <Route
                path="/admin/oferty/aktywne"
                element={<AdminOrdersActive />}
              />
              <Route
                path="/admin/oferty/doakceptacji"
                element={<AdminOrdersToAccept />}
              />
              <Route
                path="/admin/oferty/wstrzymane"
                element={<AdminOrdersStopped />}
              />
              <Route path="/admin/uzytkownicy" element={<AdminUsers />} />
              <Route
                path="/admin/uzytkownicy/:user_id"
                element={<AdminUserDetail />}
              />
              <Route
                path="/admin/kategorie/utworz"
                element={<AdminCategoryDetail />}
              />
              <Route
                path="/admin/kategorie/:cat_id"
                element={<AdminCategoryDetail />}
              />
              <Route path="/admin/zgloszenia" element={<AdminMalwares />} />
              <Route
                path="/admin/zgloszenia/:cat_id"
                element={<AdminMalwareDetail />}
              />
              <Route path="/admin/banery" element={<AdminBaners />} />
              <Route
                path="/admin/banery/:baner_id"
                element={<AdminBanerDetail />}
              />
              <Route path="/admin/transakcje" element={<AdminTransactions />} />
              <Route
                path="/admin/transakcje/:transakcja_id"
                element={<AdminTransactionsDetail />}
              />
              <Route path="/admin/kody-rabatowe" element={<AdminDiscounts />} />
              <Route path="/admin/kody-rabatowe/:discount_id" element={<AdminDiscount/>} />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
