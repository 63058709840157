import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { UserProvider } from "./context/UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <GoogleOAuthProvider clientId="463966246189-ve3lidlae8tr0i4uthu7capa9evq3ui6.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </UserProvider>
);
