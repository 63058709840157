import React, { useContext, useState, useEffect } from "react";
import { RiSendPlaneLine } from "react-icons/ri";
import { BsHeart } from "react-icons/bs";
import { FaRegUserCircle, FaExclamation } from "react-icons/fa";
import logo from "../../assets/logo.png";
import { BiMenuAltRight, BiUserCircle } from "react-icons/bi";
import { UserContext } from "../../context/UserContext";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import defaultUser from "../../assets/defaultUser.png";
import CookieConsent from "react-cookie-consent";

const Header = (props) => {
  const [menuMobile, setmenuMobile] = useState(false);
  const [userData, setUserData] = useState({});
  const authCtx = useContext(UserContext);
  const [adminExclemation, setAdminExclemation] = useState(0);

  const getUserData = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    const response = await fetch(
      `https://backend.papiermarket.pl/api/auth/me/`,
      requestOptions
    );
    const data = await response.json();
    setUserData(data);
    if (data.is_admin) {
      getCounter();
    }
  };

  const getCounter = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    const response = await fetch(
      "https://backend.papiermarket.pl/api/malware/unseen/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      setAdminExclemation(data);
    }
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      getUserData();
    }
  }, []);


  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Akceptuję"
        cookieName="PapieryCookieConsent"
        style={{ background: "#f3f3f3", color: "black" }}
        buttonStyle={{
          color: "white",
          padding: "10px 20px",
          background: "#f7aa26",
          borderRadius: "0.25rem",
          fontSize: "15px",
        }}
        expires={150}
      >
        Korzystamy z plików cookies aby zapewnić Ci jak najlepsze doświadczenia
        związane z nasza stroną!
      </CookieConsent>
      <div className="container-fluid" id="header">
        <div className="row py-3 py-md-5 px-lg-4">
          <div
            className="col-6 col-sm-6 col-md-1 col-lg-1 col-xl-1 col-xxl-1"
            id="logo"
          >
            <Link to="/" className="only_mobile">
              <img className="img-fluid" src={logo} alt="logo" />
            </Link>
          </div>
          <div
            className="col-6  col-sm-6 col-md-11 col-lg-11 col-xl-11 col-xxl-11"
            id="menu"
          >
            <div className="d-flex flex-row justify-content-end DeskopMenu">
              {authCtx.isLoggedIn && (
                <div className="menu px-lg-4">
                  <div className="messages">
                  <Link to="/moje-konto/wiadomosci">
                    <RiSendPlaneLine />
                    <span className="newMess">Wiadomości {userData.new_message && <FaExclamation/>}</span>
                    </Link>
                  </div>
                  <div className="favourite">
                    <Link to="/moje-konto/ulubione">
                    <BsHeart />
                    <span>Ulubione</span>
                    </Link>
                  </div>
                </div>
              )}

              <div className="buttons">
                <Link to="/katalog">
                  <button className="btn catalog">Katalog Firm</button>
                </Link>
                <div className="searchDropDown">
                  {authCtx.isLoggedIn ? (
                    <>
                      <Link to="/moje-konto">
                        <button className="btn your_account">
                          <FaRegUserCircle />
                          Twoje Konto
                          {adminExclemation > 0 && <FaExclamation/>}
                        </button>
                      </Link>
                      <div className="rangeCategories">
                        <div className="myCustomRow">
                          <div className="image">
                            <img
                              src={
                                userData.profile_picture
                                  ? userData.profile_picture
                                  : defaultUser
                              }
                              alt="header"
                              className="img-fluid circle"
                            />
                          </div>
                          <div className="accountData">
                            <p className="nazwa mb-0">{userData.first_name}</p>
                            <strong>Id:</strong> <span>{userData.id}</span>
                          </div>
                        </div>
                        <Link to="/moje-konto">
                          <div className="itemRange">Twoje Konto</div>
                        </Link>
                        <Link to="/moje-konto/oferty">
                          <div className="itemRange">Ogłoszenia</div>
                        </Link>
                        <Link to="/moje-konto/ulubione">
                          <div className="itemRange">Ulubione</div>
                        </Link>
                        <div
                          className="itemRange"
                          onClick={() => authCtx.logout()}
                        >
                          Wyloguj się
                        </div>
                      </div>
                    </>
                  ) : (
                    <Link to="/zaloguj-sie">
                      <button className="btn your_account">
                        <FaRegUserCircle />
                        Zaloguj się
                      </button>
                    </Link>
                  )}
                </div>
                <Link
                  to={
                    authCtx.isLoggedIn
                      ? "/moje-konto/dodaj-oferte"
                      : "/zaloguj-sie"
                  }
                >
                  <button className="btn add_offer">Dodaj ogłoszenie</button>
                </Link>
              </div>
            </div>
            <div className="mobileMenu">
              {authCtx.isLoggedIn ? (
              <Link to="/moje-konto">
              <BiUserCircle />
              </Link>
              ) : (
                <Link to="/zaloguj-sie">
                <BiUserCircle />
                </Link>
              )}
              <BiMenuAltRight onClick={() => setmenuMobile(true)} />
            </div>
          </div>
          <div className="col-sm-12 col-md-0 col-lg-0 col-xl-1 col-xxl-1"></div>
        </div>
        {props.children}
      </div>
      <div className={menuMobile ? "menuOverflow" : "menuHide"}>
        <div className="menuWrapper">
          <div className="row py-3" id="menuWrapperRow">
            <div className="col-9" id="mobileMenuImg logo">
              <Link to="/">
                <img className="img-fluid logo" src={logo} alt="logo" />
              </Link>
            </div>
            <div className="col-3" id="mobileMenuClose">
              <CgClose onClick={() => setmenuMobile(false)} />
            </div>
          </div>
          <div className="menuItems">
            {authCtx.isLoggedIn && (
              <div className="item">
                <Link to="/moje-konto">
                  <span>Panel</span>
                </Link>
              </div>
            )}
            <div className="item">
              <Link to="/moje-konto/oferty">
                <span>Oferty </span>
              </Link>
            </div>
            <div className="item">
              <Link to="/katalog">
                <span>Katalog firm </span>
              </Link>
            </div>
            <div className="item">
              <Link to="http://blog.papiermarket.pl">
                <span>Blog</span>
              </Link>
            </div>
            {!authCtx.isLoggedIn && (
              <div className="item">
                <Link to="/zarejestruj-sie">
                  <span>Rejestracja</span>
                </Link>
              </div>
            )}
          </div>
          <div className="buttonItem mobileBottomContent" style={{background: "unset"}}>
            {authCtx.isLoggedIn ? (
              <button onClick={authCtx.logout} className="loginButtonMenu">
                Wyloguj się
              </button>
            ) : (
              <Link to="/zaloguj-sie">
                <button className="loginButtonMenu">Logowanie</button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
