import React, { useState, useEffect } from "react";
import AdsComponent from "./AdsComponent";


const Wide = (props) => {
  let amount = 1;
  if (props.amount) {
    amount = props.amount;
  }
  const [baner, setBaner] = useState([]);

  const getBaner = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `https://backend.papiermarket.pl/api/baner/wide/?amount=${amount}`,
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      setBaner(data);
    }
  };

  const handleClick = async (b) => {
    await fetch(`https://backend.papiermarket.pl/api/baner/click/${b.id}/`);
    window.location.href = b.link;
  };
  useEffect(() => {
    getBaner();
  }, []);

  return (
    <div className="baners">
      <div className="container">
        <p className="smallBaner text-start mb-0">Reklama</p>
      </div>
      {baner.length > 0 ? (
        baner.map((b) => (
          <div className="container">
            <img
              onClick={() => handleClick(b)}
              src={b.image}
              alt="advertisement"
              className="img-fluid"
            />
          </div>
        ))
      ) : (
        <AdsComponent dataAdSlot='9987684634' />
      )}
    </div>
  );
};

export default Wide;
