import React from "react";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import { Link } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import logo from "../../../assets/logo.png";

const FAQ = () => {
  return (
    <>
      <div className="no_mobile detailHeader">
        <Header>
          <div className="container Hero">
            <div className="no_mobile mb-xl-5 pb-5">
              <Link to="/">
                <img className="img-fluid" src={logo} alt="logo" />
              </Link>
            </div>
            <div className="mb-5 pb-5"></div>
          </div>
        </Header>
      </div>
      <div className="container only_mobile">
        <div className="back_bread py-lg-4 py-3 mt-2">
          <div className="comeback">
            <Link
              className="d-flex flex-row comebackGap align-items-center"
              to="/"
            >
              <BsChevronLeft />
              <span className="only_mobile blackText">Wróć</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Accordion Item #1
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                pulvinar ante at ipsum elementum, vel vulputate purus egestas.
                Mauris vel porta leo. Phasellus laoreet suscipit odio, ac
                finibus ex tristique id. Vestibulum eget sapien eget eros
                elementum bibendum accumsan at ante. Fusce sit amet lorem augue.
                Praesent malesuada purus ac justo lacinia lobortis. Aliquam erat
                volutpat. Vestibulum ante ipsum primis in faucibus orci luctus
                et ultrices posuere cubilia curae; Integer non lacinia sem, eget
                volutpat tellus. Fusce in mi nunc. Sed scelerisque diam lorem,
                in facilisis neque tincidunt at. Etiam vitae aliquam eros, vel
                rhoncus est. Suspendisse feugiat sem in tellus lobortis dictum.
                Maecenas luctus dolor quam, id scelerisque leo commodo a.
                Pellentesque commodo, felis ut placerat pellentesque, sapien
                ipsum malesuada dolor, vitae egestas magna massa vitae elit.
                Integer fringilla mauris nec blandit venenatis.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Accordion Item #2
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                pulvinar ante at ipsum elementum, vel vulputate purus egestas.
                Mauris vel porta leo. Phasellus laoreet suscipit odio, ac
                finibus ex tristique id. Vestibulum eget sapien eget eros
                elementum bibendum accumsan at ante. Fusce sit amet lorem augue.
                Praesent malesuada purus ac justo lacinia lobortis. Aliquam erat
                volutpat. Vestibulum ante ipsum primis in faucibus orci luctus
                et ultrices posuere cubilia curae; Integer non lacinia sem, eget
                volutpat tellus. Fusce in mi nunc. Sed scelerisque diam lorem,
                in facilisis neque tincidunt at. Etiam vitae aliquam eros, vel
                rhoncus est. Suspendisse feugiat sem in tellus lobortis dictum.
                Maecenas luctus dolor quam, id scelerisque leo commodo a.
                Pellentesque commodo, felis ut placerat pellentesque, sapien
                ipsum malesuada dolor, vitae egestas magna massa vitae elit.
                Integer fringilla mauris nec blandit venenatis.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Accordion Item #3
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                pulvinar ante at ipsum elementum, vel vulputate purus egestas.
                Mauris vel porta leo. Phasellus laoreet suscipit odio, ac
                finibus ex tristique id. Vestibulum eget sapien eget eros
                elementum bibendum accumsan at ante. Fusce sit amet lorem augue.
                Praesent malesuada purus ac justo lacinia lobortis. Aliquam erat
                volutpat. Vestibulum ante ipsum primis in faucibus orci luctus
                et ultrices posuere cubilia curae; Integer non lacinia sem, eget
                volutpat tellus. Fusce in mi nunc. Sed scelerisque diam lorem,
                in facilisis neque tincidunt at. Etiam vitae aliquam eros, vel
                rhoncus est. Suspendisse feugiat sem in tellus lobortis dictum.
                Maecenas luctus dolor quam, id scelerisque leo commodo a.
                Pellentesque commodo, felis ut placerat pellentesque, sapien
                ipsum malesuada dolor, vitae egestas magna massa vitae elit.
                Integer fringilla mauris nec blandit venenatis.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer ads={false} />
    </>
  );
};

export default FAQ;
