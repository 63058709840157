import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import logo from "../../assets/logo.png";
import dummyAds from "../../assets/dummyAds.png";
import { RiFacebookFill, RiSendPlaneLine } from "react-icons/ri";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BsSearch, BsHeart } from "react-icons/bs";
import { AiOutlinePlusCircle, AiOutlineUserAdd } from "react-icons/ai";
import Wide from "../Baners/Wide";

const Footer = (props) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const authCtx = useContext(UserContext);
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <>
      <div className={show ? "mobileStickyBottom" : "display_none"}>
        <div className="bottomMenu">
          <div className="bottomMenuItem">
            <Link to="/szukaj">
              <BsSearch />
              Szukaj
            </Link>
          </div>
          <div
            className="bottomMenuItem"
            id={props.active === "komentarze" ? "active" : ""}
          >
            <Link
              to={authCtx.isLoggedIn ? "/moje-konto/ulubione" : "/zaloguj-sie"}
            >
              <BsHeart />
              Obserwujesz
            </Link>
          </div>
          <div
            className="bottomMenuItem"
            id={props.active === "wiadomosci" ? "active" : ""}
          >
            <Link
              to={
                authCtx.isLoggedIn ? "/moje-konto/dodaj-oferte" : "/zaloguj-sie"
              }
            >
              <AiOutlinePlusCircle />
              Dodaj
            </Link>
          </div>
          <div
            className="bottomMenuItem"
            id={props.active === "panel" ? "active" : ""}
          >
            <Link
              to={
                authCtx.isLoggedIn ? "/moje-konto/wiadomosci" : "/zaloguj-sie"
              }
            >
              <RiSendPlaneLine />
              Wiadomości
            </Link>
          </div>
          <div
            className="bottomMenuItem"
            id={props.active === "panel" ? "active" : ""}
          >
            <Link to={authCtx.isLoggedIn ? "/moje-konto" : "/zaloguj-sie"}>
              <AiOutlineUserAdd />
              Konto
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid advertisementSection py-5 text-center">
        {props.ads || (props.ads === undefined && <Wide amount={1} />)}
      </div>
      <div className="footer py-md-5 py-3 container-fluid px-lg-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-xl-4 col-lg-6 col-xxl-4 px-0 px-xxl-5 textCenterMobile">
              <div className="logo">
                <img className="img-fluid logo" src={logo} alt="logo" />
              </div>
              <div className=" mt-3 logoFooterText">
                Papier Market - Twoja platforma dla branży poligraficznej i
                opakowaniowej!
              </div>
              <div className="social mt-5">
                <div className="item">
                  <RiFacebookFill />
                </div>
                <div className="item">
                  <BsInstagram />
                </div>
                <div className="item">
                  <BsTwitter />
                </div>
              </div>
            </div>
            <div className="col-sm-12 px-4 px-md-0 col-xl-3 col-xxl-3 col-md-4 col-lg-6 mt-5 mt-md-5 py-md-5 py-3 textCenterMobile">
              <h5>Pomoc i kontakt</h5>
              <p>
                <Link style={{ color: "white" }} to="/kontakt">
                  Formularz kontaktowy
                </Link>
              </p>
              <p>
                <Link
                  style={{ color: "white" }}
                  to="http://blog.papiermarket.pl/faq"
                >
                  Często zadawane pytania (FAQ)
                </Link>
              </p>
            </div>
            <div className="col-sm-12 px-4 px-md-0 col-md-4 col-xl-3 col-xxl-3 col-lg-6 mt-2 mt-md-5 py-md-5 py-3 textCenterMobile">
              <h5>Regulamin</h5>
              <p>
                <Link style={{ color: "white" }} to="/regulamin">
                  Zasady korzystania z serwisu
                </Link>
              </p>
              <p>
                <Link style={{ color: "white" }} to="/polityka-prywatnosci">
                  Polityka prywatności
                </Link>
              </p>
            </div>
            <div className="col-sm-12 px-4 px-md-0 col-md-4 col-lg-6 col-xl-2 col-xxl-2 mt-2 mt-md-5 py-md-5 py-3 textCenterMobile">
              <h5>Na Skróty</h5>
              <p>
                <Link
                  style={{ color: "white" }}
                  to="https://blog.papiermarket.pl"
                >
                  Blog
                </Link>
              </p>
              <p>
                <Link
                  style={{ color: "white" }}
                  to="https://blog.papiermarket.pl/funkcjonalnosc-reklam/"
                >
                  Funkcjonalność reklam
                </Link>
              </p>
              <p>
                <Link style={{ color: "white" }} to="/zarejestruj-sie">
                  Rejestracja
                </Link>
              </p>
              <p>
                <Link style={{ color: "white" }} to="/zaloguj-sie">
                  Logowanie
                </Link>
              </p>
            </div>
          </div>
          <hr className="line" />
          <p className="copyright">
            Copyright 2023 © Papier Market All right reserved | Realizacja{" "}
            <Link style={{ color: "#F7AA26" }} to="https://grupalucrum.pl">
              GrupaLucrum
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
